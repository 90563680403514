import React from "react";
import { styled } from "@mui/system";

interface GapperProps {
  gap?: number;
}

const Spacer = styled("div")<GapperProps>`
  height: ${({ gap }) => gap}px;
`;

const Gapper: React.FC<GapperProps> = ({ gap = 82 }) => {
  return <Spacer gap={gap} />;
};
export default Gapper;
