import { Typography, Container } from "@mui/material";
import { styled } from "@mui/system";

import { useNavigate } from "react-router-dom";

import { colors } from "../../themes/colors";

import Button from "../../components/Button";

const Span = styled("span")({
  color: colors.brandColor,
  fontWeight: "600",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "",
  marginBottom: theme.spacing(5),
  paddingRight: theme.spacing(20),
  [theme.breakpoints.down("md")]: {
    paddingRight: theme.spacing(0),
  },
}));

const StyledContainer = styled(Container)`
  max-width: 1440px !important;
  @media (max-width: 1600px) {
    max-width: 1200px !important;
  }
`;

export default function Description() {
  const navigate = useNavigate();

  return (
    <StyledContainer>
      <StyledTypography variant="h4">
        "CashFlowApp is revolutionizing the DeFi landscape by making
        decentralized finance <Span>accessible, secure, and profitable</Span>{" "}
        for everyone. With our innovative platform, we're empowering individuals
        to take control of their financial future, breaking down barriers and
        creating a new world of opportunities in the{" "}
        <Span>DeFi ecosystem.</Span>"
      </StyledTypography>

      <Button
        onClick={() => {
          navigate("/about-us");
        }}
      >
        View our values
      </Button>
    </StyledContainer>
  );
}
