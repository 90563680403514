import React, { useState } from "react";
import { Typography, Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/system";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { colors } from "../../themes/colors";

import Card from "../../components/Card";

import flower from "../../assets/svg/decentralized-option-icon.svg";

const StyledCarousel = styled(Carousel)({
  ".react-multi-carousel-item": {
    padding: "12px",
    "@media (max-width: 500px)": {
      // padding: "12px 60px",
    },
  },
});

const data = [
  {
    img: "",
    heading: "Transparency",
    description:
      "We believe in the transformative potential of DeFi and are dedicated to making it accessible, secure, and intuitive. We value open communication and honesty, ensuring our users are always informed and confident in their interactions with us.",
  },
  {
    img: "",
    heading: "Security",
    description:
      "Your trust is our utmost priority. We adhere to the highest standards of security and privacy, implementing robust measures to protect your assets and personal data. With CashFlowApp, you can rest assured that your financial journey is in safe hands.",
  },
  {
    img: "",
    heading: "Innovation",
    description:
      "In the dynamic world of DeFi, we are relentless in our pursuit of innovation. We continuously strive to push the boundaries, seeking out new and better ways to enhance your DeFi experience. At CashFlowApp, we're not just keeping up with the future of finance - we're shaping it.",
  },
];

export default function CoreValues() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 1536, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1200, min: 1000 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1000, min: 740 },
      items: 3,
    },
    largeMobile: {
      breakpoint: { max: 740, min: 500 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  return (
    <Container>
      <Typography variant="h2" align="center" sx={{ mb: 5 }}>
        Core Values
      </Typography>

      <StyledCarousel responsive={responsive} arrows={false}>
        {data.map((item, index) => (
          <Card>
            <Box
              component="img"
              src={flower}
              width={68}
              height={68}
              sx={{ mb: 5 }}
            />
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "600" }}>
              {item.heading}
            </Typography>
            <Typography variant="body1">{item.description}</Typography>
          </Card>
        ))}
      </StyledCarousel>
    </Container>
  );
}
