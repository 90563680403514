import React from "react";
import { styled } from "@mui/system";
import { Button as MuiButton } from "@mui/material";

type NewType =
  | "inherit"
  | "primary"
  | "secondary"
  | "success"
  | "error"
  | "info"
  | "warning"
  | undefined;

interface ButtonProps {
  children: React.ReactNode;
  startIcon?: React.ReactElement;
  onClick?(): void;
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  variant?: "text" | "outlined" | "contained";
  color?: NewType;
  padding?: string;
  fullWidth?: boolean;
  paddingMobile?: boolean;
}

const StyledButton = styled(MuiButton)<ButtonProps>`
  border-radius: 50px;
  padding: ${({ padding }) => (padding ? padding : "12px 40px")} !important;
  text-transform: none;
  // font-weight: 500;
  width: ${({ fullWidth }) => fullWidth && "100%"} !important;

  @media (max-width: 600px) {
    padding: ${({ paddingMobile }) => paddingMobile && "8px 18px"} !important;
    font-size: ${({ paddingMobile }) => paddingMobile && "0.75rem"} !important;
  }
`;

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  variant = "contained",
  color,
  padding,
  paddingMobile,
  startIcon,
  fullWidth,
}) => {
  return (
    <StyledButton
      variant={variant}
      color={color}
      padding={padding}
      paddingMobile={paddingMobile}
      startIcon={startIcon}
      fullWidth={fullWidth}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </StyledButton>
  );
};
export default Button;
