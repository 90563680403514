import { createTheme } from "@mui/material/styles";
import { colors } from "./colors";

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: colors.lightBackGround,
      paper: colors.lightCard,
    },
    primary: {
      main: colors.lightButton,
    },
    secondary: {
      main: colors.brandColor,
      contrastText: colors.brandPrimaryText,
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif', // Default font for the theme
    h1: {
      fontSize: "4rem", // 60px
      fontFamily: '"General Sans", sans-serif',
      "@media (max-width:1600px)": {
        fontSize: "3.2rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2.8rem",
      },
    },
    h2: {
      fontSize: "3.3rem", // 46.4px
      fontFamily: '"General Sans", sans-serif',
      "@media (max-width:600px)": {
        fontSize: "2.1rem",
      },
    },
    h3: {
      fontSize: "2.4rem", // 32px
      fontFamily: '"General Sans", sans-serif',
      "@media (max-width:600px)": {
        fontSize: "1.9rem",
      },
    },
    h4: {
      fontSize: "2.2rem", // 30px
      fontFamily: '"General Sans", sans-serif',
      lineHeight: "52px",
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
        lineHeight: "32px",
      },
    },
    h5: {
      fontSize: "2rem", // 26px
      fontFamily: '"General Sans", sans-serif',
      lineHeight: "40px",
      "@media (max-width:600px)": {
        fontSize: "1.7rem",
        lineHeight: "30px",
      },
    },
    h6: {
      fontFamily: '"General Sans", sans-serif',
    },
  },
});

// Define dark theme colors and styles
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: colors.darkBackground,
      paper: colors.darkCard,
    },
    primary: {
      main: colors.darkButton,
    },
    secondary: {
      main: colors.brandColor,
      contrastText: colors.brandPrimaryText,
    },
  },
  typography: {
    fontFamily: '"Poppins", sans-serif', // Default font for the theme
    h1: {
      fontSize: "4rem", // 60px
      fontFamily: '"General Sans", sans-serif',
      "@media (max-width:1600px)": {
        fontSize: "3.2rem",
      },
      "@media (max-width:600px)": {
        fontSize: "2.8rem",
      },
    },
    h2: {
      fontSize: "3.3rem", // 46.4px
      fontFamily: '"General Sans", sans-serif',
      "@media (max-width:600px)": {
        fontSize: "2.1rem",
      },
    },
    h3: {
      fontSize: "2.4rem", // 32px
      fontFamily: '"General Sans", sans-serif',
      "@media (max-width:600px)": {
        fontSize: "1.9rem",
      },
    },
    h4: {
      fontSize: "2.2rem", // 30px
      fontFamily: '"General Sans", sans-serif',
      lineHeight: "52px",
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
        lineHeight: "32px",
      },
    },
    h5: {
      fontSize: "2rem", // 26px
      fontFamily: '"General Sans", sans-serif',
      lineHeight: "40px",
      "@media (max-width:600px)": {
        fontSize: "1.7rem",
        lineHeight: "30px",
      },
    },
    h6: {
      fontFamily: '"General Sans", sans-serif',
    },
  },
});
