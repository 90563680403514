import { styled } from "@mui/system";
import { Card as MuiCard } from "@mui/material";

interface CardProps {
  padding?: string;
  height?: string;
  backgroundImage?: string;
  color?: string;
  paddingMobile?: boolean;
}

const Card = styled(MuiCard)<CardProps>`
  background-image: ${({ backgroundImage }) =>
    backgroundImage && `url(${backgroundImage})`};
  border-radius: 20px;
  box-shadow: 0 0 16px rgb(0, 0, 0, 0.1);
  color: ${({ color }) => color} !important;
  padding: ${({ padding }) => (padding ? padding : "32px 20px")} !important;
  height: ${({ height }) => (height ? height : "100%")} !important;

  @media (max-width: 768px) {
    padding: ${({ paddingMobile }) => paddingMobile && "32px 20px"} !important;
  }
`;

export default Card;
