import * as React from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import { styled } from "@mui/system";

import { ReactComponent as ToggleMenuIcon } from "../assets/svg/toggle-menu-icon.svg";

import Logo from "../assets/png/logo.png";

type Anchor = "top" | "left" | "bottom" | "right";

interface DrawerProps {
  handleNavigateToSection: (sectionId: string) => void;
  onToggleTheme: () => void;
  isDarkMode: boolean;
}

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(4, 2),
  // necessary for content to be below app bar
  // ...theme.mixins.toolbar,
  justifyContent: "start",
}));

const Hamburger = styled(ToggleMenuIcon)(({ theme }) => ({
  width: "28px",
  path: {
    fill: theme.palette.mode === "light" ? "black" : "white",
  },
}));

const SideDrawer: React.FC<DrawerProps> = ({
  handleNavigateToSection,
  onToggleTheme,
  isDarkMode,
}) => {
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DrawerHeader>
        <Box
          component="img"
          src={Logo}
          width={120}
          onClick={() => navigate("/")}
        />
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem
          onClick={() => handleNavigateToSection("feature-section")}
          disablePadding
        >
          <ListItemButton>
            <ListItemText primary="Features" />
          </ListItemButton>
        </ListItem>
        {[
          ["/about-us", "About Us"],
          // ["/faqs", "FAQs"],
          // ["/contact-us", "Contact Us"],
        ].map((text, index) => (
          <ListItem
            key={text[0]}
            onClick={() => navigate(text[0])}
            disablePadding
          >
            <ListItemButton>
              <ListItemText primary={text[1]} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <List
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            sx={{ background: "none" }}
          >
            Theme
          </ListSubheader>
        }
      >
        <ListItem
          onClick={isDarkMode ? onToggleTheme : () => console.log("")}
          disablePadding
        >
          <ListItemButton>
            <ListItemText primary="Light" />
          </ListItemButton>
        </ListItem>
        <ListItem
          onClick={!isDarkMode ? onToggleTheme : () => console.log("")}
          disablePadding
        >
          <ListItemButton>
            <ListItemText primary="Dark" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      <IconButton>
        <Hamburger onClick={toggleDrawer("left", true)} />
      </IconButton>
      <Drawer
        anchor="left" // Set anchor to "left" to open the sidebar on the left side
        open={state.left}
        onClose={toggleDrawer("left", false)}
      >
        {list("left")}
      </Drawer>
    </div>
  );
};
export default SideDrawer;
