import React from "react";
import { styled } from "@mui/system";
import { Typography, Container, Box } from "@mui/material";

import Button from "../../components/Button";
import Gapper from "../../components/Gapper";
import CoreValues from "./CoreValues";
import Team from "./Team";
import RoadMap from "./RoadMap";
import Description from "./Description";
import { colors } from "../../themes/colors";

const ButtonBox = styled(Box)({
  marginTop: "40px",
  textAlign: "center",
});

const Span = styled("span")({
  color: colors.brandColor,
});

const AboutUs = () => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <>
      <Container maxWidth="md">
        <Typography variant="h2" align="center" gutterBottom>
          Transforming Finance, Empowering You: Unveiling the{" "}
          <Span>CashFlowApp Journey</Span>
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary">
          At CashFlowApp, we're more than just a platform - we're a catalyst for
          change. We recognize the transformative potential of decentralized
          finance (DeFi) and its capacity to reshape the financial ecosystem.
          Our mission is to seamlessly integrate traditional finance with DeFi,
          making it universally accessible. We're committed to creating a world
          where financial empowerment is not a distant dream but a readily
          available reality for everyone.
        </Typography>
        <ButtonBox>
          <Button
            color="secondary"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? "Coming Soon" : "Launch App"}
          </Button>
        </ButtonBox>
      </Container>

      <Gapper />

      <Description />

      <Gapper />

      <CoreValues />

      <Gapper />

      <Team />

      <Gapper />

      {/* <Testimonials />

      <Gapper />

      <Partnerships />

      <Gapper /> */}

      <RoadMap />
    </>
  );
};

export default AboutUs;
