import { Box, Container, Typography, Card as MuiCard } from "@mui/material";
import { styled } from "@mui/system";

import Card from "../../components/Card";
// import Button from "../../components/Button";
import Gapper from "../../components/Gapper";

import CashflowIcon from "../../assets/png/cashflow-icon.png";
import { colors } from "../../themes/colors";

const CustomGapper = styled(Box)(({ theme }) => ({
  background:
    theme.palette.mode === "light"
      ? `linear-gradient(120deg, transparent 64%, #D8EDE5, transparent 99% )`
      : "",
  height: "20px",
  position: "relative",

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Circle = styled(Box)(({ theme }) => ({
  width: "200px",
  height: "200px",
  position: "absolute",
  borderRadius: "50%",
  right: "0",
  background: theme.palette.mode === "dark" ? colors.darkBackground : "#D8EDE5",
  transform: "translate(-50%, -50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const LogoWrapper = styled(MuiCard)(({ theme }) => ({
  boxShadow: "0 0 16px rgb(0, 0, 0, 0.1)",
  width: "160px",
  height: "160px",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  paddingRight: theme.spacing(30),
  [theme.breakpoints.down("md")]: {
    paddingRight: theme.spacing(0),
  },
}));

export default function EmpoweringUser() {
  return (
    <>
      <Container maxWidth="md" sx={{ mb: 7 }}>
        <Typography gutterBottom variant="h2" align="center">
          Empowering User Potential with the $CFA Token
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary">
          At the core of CashFlowApp lies our native token, $CFA, a powerful
          tool that not only incentivizes user engagement but also cultivates a
          sense of shared ownership in our Web3 community.
        </Typography>
      </Container>

      <Container>
        <Card padding="60px" paddingMobile>
          <Typography variant="h3" gutterBottom>
            Token Utility
          </Typography>
          <StyledTypography variant="body1" color="text.secondary">
            Holding and deploying $CFA within our ecosystem unlocks a suite of
            exclusive benefits and rewards. A key feature of $CFA is its staking
            capability in our revenue share staking pool, which grants users
            membership in CashFlowApp's Decentralized Autonomous Organization
            (DAO). This unique opportunity allows users to actively participate
            in steering the platform's trajectory and growth by voting on
            community governance proposals, truly embodying the spirit of
            decentralization.
          </StyledTypography>
          {/* <Button>View docs</Button> */}
        </Card>

        <CustomGapper>
          <Circle>
            <LogoWrapper>
              <Box component={"img"} src={CashflowIcon} width={124} />
            </LogoWrapper>
          </Circle>
        </CustomGapper>

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Gapper gap={20} />
        </Box>

        <Card padding="60px" paddingMobile>
          <Typography variant="h3" gutterBottom>
            Token Governance
          </Typography>
          <StyledTypography variant="body1" color="text.secondary">
            Being a $CFA token holder transcends mere platform stake; it's a
            voice in our vibrant community. As a $CFA token holder, you are
            entrusted with the power to participate in the decision-making
            process. Your voting rights enable you to shape the future
            development and direction of CashFlowApp, making you a pivotal
            player in our mission to redefine the DeFi landscape.
          </StyledTypography>
          {/* <Button>View docs</Button> */}
        </Card>
      </Container>
    </>
  );
}
