import React, { useState, useEffect } from "react";
import { Container, Typography, Box } from "@mui/material";
import { styled } from "@mui/system";

import { colors } from "../../themes/colors";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import Card from "../../components/Card";

import { ReactComponent as Img1 } from "../../assets/svg/decentralized-insurance-icon.svg";
import { ReactComponent as Img2 } from "../../assets/svg/decentralized-option-icon.svg";
import { ReactComponent as Img3 } from "../../assets/svg/tokenized-assets-icon.svg";
import { ReactComponent as Img4 } from "../../assets/svg/access-to-various-deFi-launchpads-icon.svg";
import { ReactComponent as Img5 } from "../../assets/svg/participate-in-DAOs-icon.svg";
import { ReactComponent as Img6 } from "../../assets/svg/risk-management-tools-icon.svg";

import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

const StyledCarousel = styled(Carousel)({
  ".react-multi-carousel-item": {
    padding: "12px",
    "@media (max-width: 500px)": {
      // padding: "12px 60px",
    },
  },
  ".center": {
    background: colors.brandColor,
    color: colors.brandPrimaryText,
    ".flower-image": {
      path: { fill: colors.brandPrimaryText },
    },
  },
});

const StyledButtonGroupWrapper = styled(Box)`
  position: absolute;
  display: flex;
  align-items: center;
  left: 50% !important;
  top: 80% !important;
  transform: translate(-50%, -50%);
`;

const StyledDragButton = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.main,
  color:
    theme.palette.mode === "light" ? colors.brandPrimaryText : colors.lightText,
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  fontWeight: "600",
  opacity: "0.8",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ButtonGroup = ({ next, previous, goToSlide, ...rest }: any) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <StyledButtonGroupWrapper>
      <ArrowLeftIcon onClick={() => previous()} sx={{ cursor: "pointer" }} />
      <StyledDragButton>Drag</StyledDragButton>
      <ArrowRightIcon
        onClick={() => goToSlide(currentSlide + 1)}
        sx={{ cursor: "pointer" }}
      />
    </StyledButtonGroupWrapper>
  );
};

export default function AdditionalFeature() {
  const [carouselCenter, setCarouselCenter] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 1536, min: 1200 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1200, min: 1000 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1000, min: 740 },
      items: 3,
    },
    largeMobile: {
      breakpoint: { max: 740, min: 500 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const data = [
    {
      id: 1,
      img: (
        <Img1
          className="flower-image"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
      ),
      heading: "Decentralized Insurance",
      description:
        "Secure your investments with our decentralized insurance, providing an extra layer of protection for your assets.",
    },
    {
      id: 2,
      img: (
        <Img2
          className="flower-image"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
      ),
      heading: "Decentralized Option Contracts and Perpetual Contracts",
      description:
        "Enjoy seamless transactions across multiple blockchain networks with our Multi-Chain Bridge. Swap tokens effortlessly and capitalize on opportunities across the DeFi landscape.",
    },
    {
      id: 3,
      img: (
        <Img3
          className="flower-image"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
      ),
      heading: "Tokenized Assets",
      description:
        "Invest in a wide variety of investment options, including tokenized stocks, commodities, real estate, decentralized Index funds, and global fiat currency stablecoins.",
    },
    {
      id: 4,
      img: (
        <Img4
          className="flower-image"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
      ),
      heading: "Access to Various DeFi Launchpads",
      description:
        "Stay ahead of the curve by gaining early access to new DeFi projects through our partner launchpads.",
    },
    {
      id: 5,
      img: (
        <Img5
          className="flower-image"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
      ),
      heading: "Participate in DAOs",
      description:
        "Have a say in the future of CashFlowApp by participating in our Decentralized Autonomous Organization (DAO). Your voice matters to us.",
    },
    {
      id: 6,
      img: (
        <Img6
          className="flower-image"
          style={{ width: "68px", height: "68px", marginBottom: "40px" }}
        />
      ),
      heading: "Risk Management Tools",
      description:
        "Mitigate risks with our suite of management tools, including yield strategy stop-loss, unique risk data analysis, and automated portfolio diversification.",
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const afterChangeHandler = (slide: any, slideData: any) => {
    let centeredSlideIndex;

    centeredSlideIndex =
      (slideData.currentSlide + Math.floor(slideData.slidesToShow / 2)) %
      data.length;

    setCarouselCenter(centeredSlideIndex);
  };

  return (
    <>
      <Container>
        <Typography
          variant="h2"
          sx={{ textAlign: { xs: "center", md: "left" }, mb: 5 }}
        >
          Additional Features
        </Typography>
      </Container>

      <StyledCarousel
        centerMode={
          windowWidth >= 1000 || (windowWidth > 500 && windowWidth < 740)
        }
        infinite={true}
        responsive={responsive}
        afterChange={afterChangeHandler}
        arrows={false}
        customButtonGroup={<ButtonGroup />}
      >
        {data.map((item, index) => (
          <Card
            key={item.id}
            className={index === carouselCenter ? "center" : ""}
          >
            {item.img}

            <Typography variant="h6" gutterBottom>
              {item.heading}
            </Typography>
            <Typography
              variant="body1"
              color={index === carouselCenter ? "center" : "text.secondary"}
            >
              {item.description}
            </Typography>
          </Card>
        ))}
      </StyledCarousel>
    </>
  );
}
