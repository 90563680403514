import React from "react";
import { Box, Grid, Typography, Stack, Chip } from "@mui/material";
import { styled } from "@mui/system";
import Gapper from "../../components/Gapper";

const Wrapper = styled(Box)<{ isActive?: boolean }>(({ theme, isActive }) => ({
  background: isActive ? theme.palette.secondary.main : "",
  border:
    theme.palette.mode === "light"
      ? "1px solid rgba(0, 0, 0, 0.1)"
      : "1px solid rgba(255, 255, 255, 0.12)",
  color: isActive ? "white" : "",
  cursor: "pointer",
  padding: "34px 16px",
  height: "100%",
}));

const StyledList = styled("ol")({
  paddingLeft: "18px",
});

const StyledListItem = styled("li")({
  paddingBottom: "12px",
});

const StyledChip = styled(Box)<{ isActive?: boolean }>(
  ({ theme, isActive }) => ({
    backgroundColor: "#00AD6F14",
    border: isActive
      ? "1px solid white"
      : `1px solid ${theme.palette.secondary.main}`,
    borderRadius: "100px",
    color: isActive ? "white" : theme.palette.secondary.main,
    cursor: "unset",
    display: "inline-flex",
    fontSize: "0.8125rem",
    padding: "4px 8px",
  })
);

const data = [
  {
    question: "Q1 2024",
    a: "Integrate farming and staking strategies on EVM based chains adding 3 more protocols",
    b: "Build tutorial mode for seamless onboarding of users",
    c: "Build UI for seamless lending and borrowing feature",
    d: "Implement stop loss feature for yield strategies",
    e: "Implement account abstraction for seamless user experience",
    f: "Integrate on and off ramp through fiat and crypto through moon pay",
  },
  {
    question: "Q2 2024",
    a: "Launch cashflowapp dApp",
    b: "Integrate lending and borrowing on ethereum",
    c: "Integrate farming strategies over EVM based chains adding 3 more protocols",
    d: "Build UI for mobile application",
    e: "Build system for automated yield diversification and liquidity management",
    f: "Build in-depth token tracker panel for my wallet",
  },
  {
    question: "Q3 2024",
    a: "Build automated security service for cashflow application contracts",
    b: "Launch beta version of mobile application",
    c: "Expand staking and farming strategies over non evm chains such as near, cosmos, and solana",
    d: "Integrate decentralized options vault",
    e: "Integrate insurance protocol",
    f: "Launch CFA token",
  },
  {
    question: "Q4 2024",
    a: "Integrate opensea pro for NFT marketplace aggregation",
    b: "Integrate synthetic asset trading and minting services for tokenized digital assets",
    c: "Integrate perpetual trading platforms",
    d: "Build digital asset debit card service",
    e: "Integrate launchpad",
    f: "Build UI for cashflowapp pro",
  },
];

export default function RoadMap() {
  const [activeIndex, setActiveIndex] = React.useState(null);

  const handleWrapperClick = (index: any) => {
    setActiveIndex(index);
  };

  return (
    <>
      <Typography variant="h2" align="center" sx={{ mb: 5 }}>
        Roadmap
      </Typography>

      <Grid container>
        {data.map((item, index) => (
          <Grid xs={6} md={3}>
            <Wrapper
              isActive={index === activeIndex}
              onClick={() => handleWrapperClick(index)}
            >
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="h6" fontWeight="600">
                  {item.question}
                </Typography>
                <StyledChip isActive={index === activeIndex}>Future</StyledChip>
              </Stack>

              <Gapper gap={10} />

              <Typography variant="body2">
                <StyledList>
                  <StyledListItem>{item.a}</StyledListItem>
                  <StyledListItem>{item.b}</StyledListItem>
                  <StyledListItem>{item.c}</StyledListItem>
                  <StyledListItem>{item.d}</StyledListItem>
                  <StyledListItem>{item.e}</StyledListItem>
                  <StyledListItem>{item.f}</StyledListItem>
                </StyledList>
              </Typography>
            </Wrapper>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
