import React from "react";
import { styled } from "@mui/system";
import {
  Box,
  BoxProps,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Container,
} from "@mui/material";

import { colors } from "../../themes/colors";

import Card from "../../components/Card";

import stakingDeposit from "../../assets/png/staking-deposit-img.png";
import borrowingAndLending from "../../assets/png/borrowing-and-lending-img.png";
import SphereLinesTop from "../../assets/png/sphere-lines-2.png";
import SphereLinesBottom from "../../assets/png/sphere-lines-3.png";
import greenGradientLeft from "../../assets/png/green-gradient-left.png";

import Eth from "../../assets/svg/ethereum.svg";
import polygon from "../../assets/svg/polygon.svg";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const network = [
  {
    value: "Ethereum",
    label: "Ethereum",
    img: Eth,
  },
  {
    value: "Polygon",
    label: "Polygon",
    img: polygon,
  },
];

interface ImgBoxProps extends BoxProps {
  src: string;
}

const SphereLineTopRight = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 1,
}));

const SphereLineBottomLeft = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: "5%",
  zIndex: 1,
}));

const BgGradientColoLeft = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  left: 0,
}));

const StyledLabel = styled(Typography)`
  font-weight: 600;
  margin-bottom: 12px;
`;

const StyledTextField = styled(TextField)(({ theme }) => ({
  zIndex: 2,
  background:
    theme.palette.mode === "dark" ? colors.darkCard : colors.lightCard,
  ".MuiInputBase-root": {
    borderRadius: "12px",
    ".Mui-disabled": {
      "-webkit-text-fill-color": theme.palette.primary.main + "!important",
    },
  },
  ".MuiSelect-select": {
    padding: "8px",
  },
  ".MuiSelect-icon": {
    fontSize: "xxx-large",
    color: theme.palette.primary.main + "!important",
  },
}));

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const StyledCardContent = styled(Box)`
  padding: 40px;
  padding-bottom: 60px;
  @media (max-width: 768px) {
    padding: 32px 20px !important;
  }
`;

const AvatarWrapper = styled(Box)`
  margin: 16px 0px;
  text-align: center;
`;

const AvatarIcon = styled(Box)(({ theme }) => ({
  borderRadius: "100px",
  background:
    theme.palette.mode === "light"
      ? colors.lightTextField
      : colors.darkTextField,
  display: "inline-block;",
  width: "50px",
  height: "50px",
  padding: "12px",
}));

const StyledContainer = styled(Container)`
  max-width: 1440px !important;
  @media (max-width: 1600px) {
    max-width: 1200px !important;
  }
`;

export default function Features() {
  return (
    <StyledContainer maxWidth="lg" id="feature-section">
      <Typography variant="h2" align="center" sx={{ mb: 5 }}>
        Features
      </Typography>
      {/* <BgGradientColoLeft
        component="img"
        src={greenGradientLeft}
        width={"100%"}
      /> */}
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <StyledCard padding="0px">
            <StyledCardContent>
              <Typography variant="h3" gutterBottom>
                Staking & Farming
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Unlock the power of CashFlowApp to access premier staking and
                farming investment opportunities across leading blockchain
                networks. Maximize your earnings with the finest DeFi options
                available.
              </Typography>
            </StyledCardContent>
            <Box component="img" src={stakingDeposit} width="90%" />
          </StyledCard>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledCard padding="0px">
            <StyledCardContent>
              <Typography variant="h3" gutterBottom>
                Borrowing and Lending
              </Typography>
              <Typography variant="body1" color="text.secondary">
                Leverage your assets with our secure and transparent borrowing
                and lending services. Maximize your earnings and take control of
                your financial future.
              </Typography>
            </StyledCardContent>
            <Box component="img" src={borrowingAndLending} width="90%" />
          </StyledCard>
        </Grid>
        <Grid item xs={12}>
          <Card
            padding="80px 48px"
            paddingMobile
            style={{ position: "relative" }}
          >
            <SphereLineTopRight
              component={"img"}
              src={SphereLinesTop}
              width={"30%"}
            />
            <SphereLineBottomLeft
              component={"img"}
              src={SphereLinesBottom}
              width={"50%"}
            />
            <Grid item container spacing={8}>
              <Grid item xs={12} md={6}>
                <Typography variant="h3" gutterBottom>
                  Multi-Chain Bridge and <br /> Token Exchange
                </Typography>

                <Typography variant="body1" color="text.secondary">
                  Enjoy seamless transactions across multiple blockchain
                  networks with our Multi-Chain Bridge. Swap tokens effortlessly
                  and capitalize on opportunities across the DeFi landscape.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box component="form">
                  <div>
                    <StyledLabel>Select Source Network</StyledLabel>
                    <StyledTextField
                      fullWidth
                      select
                      disabled
                      defaultValue="Ethereum"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {network.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            <Box
                              component="img"
                              src={option.img}
                              width={50}
                              height={50}
                            />
                            <Typography variant="body2" fontWeight="600">
                              {option.label}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </div>

                  <AvatarWrapper>
                    <AvatarIcon>
                      <ArrowDownwardIcon />
                    </AvatarIcon>
                  </AvatarWrapper>
                  {/*  */}

                  <div>
                    <StyledLabel>Select Destination Network</StyledLabel>
                    <StyledTextField
                      fullWidth
                      select
                      disabled
                      defaultValue="Polygon"
                      variant="filled"
                      InputProps={{
                        disableUnderline: true,
                      }}
                    >
                      {network.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Stack
                            spacing={2}
                            direction="row"
                            alignItems="center"
                          >
                            <Box
                              component="img"
                              src={option.img}
                              width={50}
                              height={50}
                            />
                            <Typography variant="body2" fontWeight="600">
                              {option.label}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </StyledTextField>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </StyledContainer>
  );
}
