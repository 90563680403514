import React from "react";
import { Typography, Container } from "@mui/material";
import { styled } from "@mui/system";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.7)" : "",
  paddingRight: theme.spacing(10),
  [theme.breakpoints.down("md")]: {
    paddingRight: theme.spacing(0),
  },
}));

export default function Description() {
  return (
    <Container maxWidth="md">
      <StyledTypography variant="h5">
        In the ever-evolving realm of Web3, CashFlowApp was born in 2022 with a
        vision to simplify and democratize DeFi. We saw a world of financial
        opportunities locked behind complex terminologies and intimidating
        interfaces. This realization ignited our unwavering determination to
        drive change. Presently, we stand tall as a trusted platform, empowering
        users to seamlessly navigate the intricate DeFi landscape and actualize
        their cherished financial aspirations.
      </StyledTypography>
    </Container>
  );
}
