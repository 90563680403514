import React from "react";
import { styled } from "@mui/system";
import {
  Box,
  Grid,
  Typography,
  Container,
  Stack,
  BoxProps,
} from "@mui/material";
import SouthIcon from "@mui/icons-material/South";

import { colors } from "../../themes/colors";

import Button from "../../components/Button";

import greenGradientRight from "../../assets/png/green-gradient-right.png";
import landingImage from "../../assets/png/landing.png";

interface ImgBoxProps extends BoxProps {
  src: string;
}

const Span = styled("span")({
  color: colors.brandColor,
  fontWeight: "600",
});

const StyledScroll = styled(Box)({
  background: "transparent",
  border: `2px solid ${colors.brandColor}`,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px",
});

const BgGradientColoRight = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  top: "40%",
  right: 0,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(18),
  [theme.breakpoints.down("md")]: {
    paddingRight: theme.spacing(0),
  },
}));

const StyledContainer = styled(Container)`
  padding-left: 100px !important;
  @media (max-width: 1200px) {
    padding-left: 0px !important;
  }
`;

const Landing: React.FC = () => {
  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <Box style={{ position: "relative" }}>
      <StyledContainer style={{ maxWidth: "1800px" }}>
        <Grid container rowSpacing={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h1" sx={{ mb: 6 }}>
              Unlock the Power of DeFi with <Span>CashFlowApp</Span>
            </Typography>
            <StyledTypography
              sx={{ mb: 6 }}
              variant="subtitle1"
              color="text.secondary"
            >
              Experience a user-friendly platform designed to simplify your
              journey into decentralized finance. With CashFlowApp, you gain
              access to a suite of powerful DeFi features, all secured by
              top-tier risk management tools.
            </StyledTypography>
            <Button
              color="secondary"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {isHovered ? "Coming Soon" : "Launch App"}
            </Button>
            <Box sx={{ display: { xs: "none", md: "block" } }}>
              <Stack direction="row" alignItems="center" spacing={1} mt={8}>
                <StyledScroll>
                  <SouthIcon color={"secondary"} fontSize="large" />
                </StyledScroll>
                <Typography color={"secondary"}>Scroll to explore</Typography>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box component="img" src={landingImage} width="100%" />
          </Grid>
        </Grid>
      </StyledContainer>
      <BgGradientColoRight
        component="img"
        src={greenGradientRight}
        width="50%"
        height="100%"
      />
    </Box>
  );
};

export default Landing;
