import React, { useContext } from "react";
import { Box, BoxProps, Toolbar } from "@mui/material";
import { styled } from "@mui/system";
import { useLocation } from "react-router-dom";

import ThemeContext from "../contexts/ThemeContext";
import SphereLines from "../assets/png/sphere-lines-1.png";
import SphereLinesRight from "../assets/png/sphere-lines-4.png";
import SphereLinesLeft from "../assets/png/sphere-lines-5.png";
// import greenGradientRight from "../assets/png/green-gradient-right.png";
// import greenGradientLeft from "../assets/png/green-gradient-left.png";
// import greenGradientCenter from "../assets/png/green-gradient-center.png";

import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../utils/ScrollToTop";

interface LayoutProps {
  children: React.ReactNode;
}

interface ImgBoxProps extends BoxProps {
  src: string;
}

const SphereLineTopRight = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  top: 0,
  right: 0,
}));

const SphereLineRight = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  top: 0,
  right: 0,
}));

const SphereLineLeft = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  top: "25vh",
  left: 0,
}));

const Main = styled("div")({
  margin: "48px 0px 82px",
});

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { toggleTheme, isDarkMode } = useContext(ThemeContext);
  const location = useLocation();

  return (
    <>
      {location.pathname === "/about-us" ? (
        <>
          <SphereLineRight
            component="img"
            src={SphereLinesRight}
            width={"8vw"}
          />
          <SphereLineLeft component="img" src={SphereLinesLeft} width={"8vw"} />
        </>
      ) : location.pathname === "/" ? (
        <>
          <SphereLineTopRight
            component="img"
            src={SphereLines}
            width={"35vw"}
          />
          {/* <BgGradientColoRight
            component="img"
            src={greenGradientRight}
            width="40vw"
          /> */}
          {/* <BgGradientColoLeft
            component="img"
            src={greenGradientLeft}
            width="36vw"
          /> */}
          {/* <BgGradientColorCenter
            component="img"
            src={greenGradientCenter}
            width="40vw"
          /> */}
        </>
      ) : (
        <></>
      )}
      <Header onToggleTheme={toggleTheme} isDarkMode={isDarkMode} />
      <Toolbar sx={{ height: 120 }} />
      <ScrollToTop />
      <Main>{children}</Main>
      <Footer />
    </>
  );
};

export default Layout;
