import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import ThemeProvider from "./themes/ThemeProvider";
import Layout from "./layout";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
// import FAQs from "./pages/FAQs";
// import ContactUs from "./pages/ContactUs";
import PageNotFound from "./pages/PageNotFound";

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <CssBaseline />
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            {/* <Route path="/faqs" element={<FAQs />} />
            <Route path="/contact-us" element={<ContactUs />} /> */}
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default App;
