import React, { useContext } from "react";

import ThemeContext from "../../contexts/ThemeContext";

import Gapper from "../../components/Gapper";

import Landing from "./Landing";
import Description from "./Description";
import Features from "./Features";
import AdditionalFeature from "./AdditionalFeature";
import SupportedBlockchains from "./SupportedBlockchains";
import EmpoweringUser from "./EmpoweringUser";

const Home: React.FC = () => {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <>
      <Landing />

      <Gapper />

      <Description />

      <Gapper />

      <Features />

      <Gapper />

      <AdditionalFeature />

      <Gapper />

      <SupportedBlockchains isDarkMode={isDarkMode} />

      <Gapper />

      <EmpoweringUser />
    </>
  );
};

export default Home;
