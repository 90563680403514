import React from "react";
import { Typography, Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/system";

import michaelKonrad from "../../assets/png/michael-konrad.png";
import sakeibShaida from "../../assets/png/shakeib-shaida.png";
import Moody from "../../assets/png/Moody.png";
import khuzamaShahid from "../../assets/png/khuzama-shahid.png";
import ahsanAhmed from "../../assets/png/ahsan-ahmed.png";
import rajaHassan from "../../assets/png/raja-hasssan.png";
import aroonKessani from "../../assets/png/aroon-kessani.png";
import syedNoor from "../../assets/png/syed-noor.png";

import TwitterIcon from "@mui/icons-material/Twitter";

const ContentImage = styled(Box)``;

const ContentWrapper = styled(Box)`
  padding: 0px 24px 0px 24px;
`;

const ContentWrapperText = styled(Box)`
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  color: black;
  -webkit-backdrop-filter: blur(5px);
  padding: 12px 8px;
  text-align: center;
  margin-top: -70px;
`;

export default function Team() {
  const data = [
    { img: michaelKonrad, name: "Michael Konrad", positions: "CEO" },
    { img: sakeibShaida, name: "Shakeib Shaida", positions: "CTO" },
    { img: Moody, name: "Moody", positions: "CMO" },
    {
      img: khuzamaShahid,
      name: "Khuzama Shahid",
      positions: "Lead full stack engineer",
    },
    { img: ahsanAhmed, name: "Ahsan Ahmed", positions: "Full stack engineer" },
    { img: rajaHassan, name: "Raja Hassan", positions: "SQA engineer" },
    {
      img: aroonKessani,
      name: "Aroon Kessani",
      positions: "Smart contract engineer",
    },
    { img: syedNoor, name: "Syed Noor", positions: "Head of UI/UX" },
  ];

  return (
    <Container>
      <Typography variant="h2" align="center" sx={{ mb: 5 }}>
        Team
      </Typography>

      <Grid container spacing={4}>
        {data.map((items) => (
          <Grid item xs={12} sm={6} md={4}>
            <ContentImage>
              <Box
                component="img"
                src={items.img}
                width="100%"
                height="100%"
                sx={{ objectFit: "cover" }}
              />
            </ContentImage>
            <ContentWrapper>
              <ContentWrapperText>
                <Typography
                  variant="subtitle2"
                  fontWeight="600"
                  lineHeight="normal"
                >
                  {items.name}
                </Typography>
                <Typography variant="caption">{items.positions}</Typography>
                <Box sx={{ mt: 1, lineHeight: "0px" }}>
                  <TwitterIcon />
                </Box>
              </ContentWrapperText>
            </ContentWrapper>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
