export const colors = {
  brandColor: "#00AD6F",
  brandPrimaryText: "#F6FFFC",
  brandSecondaryText: "#00000003",

  lightBackGround: "#F7FFFC",
  lightCard: "#F6FFFC",
  lightText: "#16181D99",
  lightHeading: "#F6FFFC",
  lightButton: "#16181DCC",
  lightTextField : '#0000000D',

  darkBackground: "#051412",
  darkCard: "#011F1B",
  darkText: "#FFFFFF99",
  darkHeading: "#16181D",
  darkButton: "#F6FFFC",
  darkTextField:"#FFFFFF14"
};
