import React from "react";
import { styled } from "@mui/system";
import { Box, Container, Typography, BoxProps } from "@mui/material";

import SupportedBlockchainHorizontalDark from "../../assets/png/supported-blockchains-horizontal-dark.png";
import SupportedBlockchainHorizontalLight from "../../assets/png/supported-blockchains-horizontal-light.png";
import SupportedBlockchainVerticalLight from "../../assets/png/supported-blockchains-vertical-light.png";
import SupportedBlockchainVerticalDark from "../../assets/png/supported-blockchains-vertical-dark.png";

import greenGradientCenter from "../../assets/png/green-gradient-center.png";

interface ImgBoxProps extends BoxProps {
  src: string;
}

const BgGradientColorCenter = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  width: "100%",
  height: "100%",
  [theme.breakpoints.down("md")]: {
    width: "80%",
    height: "80%",
  },
}));

export default function SupportedBlockchains({
  isDarkMode,
}: {
  isDarkMode: boolean;
}) {
  return (
    <>
      <Container maxWidth="md" sx={{ mb: 5 }}>
        <Typography gutterBottom variant="h2" align="center">
          Supported Blockchains
        </Typography>
        <Typography variant="subtitle1" align="center" color="text.secondary">
          Multi-chain support amplifies your yield opportunities across diverse
          blockchain networks. Experience unparalleled yield diversification by
          leveraging CashFlowApp's extensive network compatibility
        </Typography>
      </Container>

      <Container style={{ position: "relative" }} maxWidth="lg">
        <BgGradientColorCenter component="img" src={greenGradientCenter} />
        <Box
          component="img"
          src={
            isDarkMode
              ? SupportedBlockchainHorizontalDark
              : SupportedBlockchainHorizontalLight
          }
          width="100%"
          height="100%"
          sx={{ display: { xs: "none", md: "block" }, objectFit: "cover" }}
        />

        <Box
          component="img"
          src={
            isDarkMode
              ? SupportedBlockchainVerticalDark
              : SupportedBlockchainVerticalLight
          }
          width="100%"
          height="100%"
          sx={{ display: { xs: "block", md: "none" }, objectFit: "cover" }}
        />
      </Container>
    </>
  );
}
