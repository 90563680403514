import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import { Typography, Box } from "@mui/material";

import Button from "../../components/Button";

import WestIcon from "@mui/icons-material/West";

const Wrapper = styled(Box)(({ theme }) => ({
  minHeight: "70vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <Typography variant="h1" fontWeight="600">
        404
      </Typography>
      <Typography color="text.secondary" variant="subtitle1" sx={{ mb: 5 }}>
        PAGE NOT FOUND!
      </Typography>
      <Button onClick={() => navigate("/")} startIcon={<WestIcon />}>
        Go back to home
      </Button>
    </Wrapper>
  );
}
