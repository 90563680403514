import React from "react";
import { styled } from "@mui/system";
import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  BoxProps,
  Divider,
  InputAdornment,
  Stack,
} from "@mui/material";

import { useLocation } from "react-router-dom";

import { colors } from "../themes/colors";

import Card from "../components/Card";
import Button from "../components/Button";
import Gapper from "../components/Gapper";

import connectAndSwap from "../assets/png/conntect-and-swap-img.png";
import SphereLinesTop from "../assets/png/sphere-lines-6.png";
import SphereLinesBottom from "../assets/png/sphere-lines-7.png";
import logo from "../assets/png/logo.png";
import GooglePlay from "../assets/png/google-play.png";
import greenGradientLeft from "../assets/png/green-gradient-left.png";

import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import TelegramIcon from "@mui/icons-material/Telegram";
// import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AppleIcon from "@mui/icons-material/Apple";

interface ImgBoxProps extends BoxProps {
  src: string;
}

const BgGradientColoLeft = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  left: 0,
}));

const SphereLineTopRight = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 1,
}));

const SphereLineBottomLeft = styled(Box)<ImgBoxProps>(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  zIndex: 1,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& fieldset": { border: "none" },
  ".MuiOutlinedInput-root": {
    border: "none",
  },

  ".MuiInputBase-root": {
    background:
      theme.palette.mode === "light"
        ? colors.lightTextField
        : colors.darkTextField,
    padding: "6px 8px 6px 18px",
    borderRadius: "50px",
  },

  ".MuiInputBase-root input": {
    padding: "10px 0px",
  },
}));

const StyledButton = styled(Box)<BoxProps>(({ theme }) => ({
  background: theme.palette.mode === "light" ? "#F6FFFC" : "#16181D",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  width: "174px",
  padding: "16px",
  borderRadius: "10px",
  cursor: "pointer",
  zIndex: 2,

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
  [theme.breakpoints.down(385)]: {
    padding: "12px 6px",
  },
}));

const StyledButtonWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: "16px",
  marginTop: 40,
}));

const AvatarIcon = styled(Box)`
  border-radius: 100px;
  background: ${colors.brandSecondaryText};
  display: inline-block;
  width: 50px;
  height: 50px;
  padding: 12px;
`;

const AvatarWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  gap: "4px",

  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "white",
  opacity: 0.8,
  paddingRight: theme.spacing(6),
  [theme.breakpoints.down("md")]: {
    paddingRight: theme.spacing(0),
  },
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: "0.75rem",
  textAlign: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const Footer: React.FC = () => {
  const location = useLocation();

  return (
    <Box style={{ position: "relative" }}>
      {location.pathname === "/" ? (
        <BgGradientColoLeft
          component="img"
          src={greenGradientLeft}
          height="100%"
          width="100%"
        />
      ) : (
        ""
      )}
      <Container component="footer">
        <Card
          padding="40px 60px"
          paddingMobile
          style={{ position: "relative", background: colors.brandColor }}
        >
          <SphereLineTopRight
            component={"img"}
            src={SphereLinesTop}
            width={"50%"}
          />
          <SphereLineBottomLeft
            component={"img"}
            src={SphereLinesBottom}
            width={"60%"}
          />
          <Grid container rowSpacing={1}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" color="white" marginBottom={2.5}>
                Embrace Financial Freedom with CashFlowApp - Start Your Journey
                Today!
              </Typography>
              <StyledTypography variant="body1">
                Unlock boundless financial possibilities with our seamless
                integration of traditional finance and DeFi. Take control of
                your financial future and join a community dedicated to
                empowering individuals like you. Experience the ease,
                transparency, and rewards that await you.
              </StyledTypography>
              <StyledButtonWrapper>
                <StyledButton>
                  <Box
                    component="img"
                    src={GooglePlay}
                    height={28}
                    margin={0.5}
                  />
                  <Box lineHeight={0}>
                    <Typography
                      lineHeight="normal"
                      variant="caption"
                      color="text.secondary"
                    >
                      Coming Soon
                    </Typography>
                    <Typography
                      lineHeight="normal"
                      variant="subtitle2"
                      fontWeight={600}
                      sx={{ mt: 0.3 }}
                    >
                      Play Store
                    </Typography>
                  </Box>
                </StyledButton>
                <StyledButton>
                  <AppleIcon fontSize="large" />
                  <Box lineHeight={0}>
                    <Typography
                      lineHeight="normal"
                      variant="caption"
                      color={"text.secondary"}
                    >
                      Coming Soon
                    </Typography>
                    <Typography
                      lineHeight="normal"
                      variant="subtitle2"
                      fontWeight={600}
                      sx={{ mt: 0.3 }}
                    >
                      App Store
                    </Typography>
                  </Box>
                </StyledButton>
              </StyledButtonWrapper>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component="img"
                src={connectAndSwap}
                width="100%"
                height="100%"
                sx={{ position: "relative", bottom: "-40px", zIndex: 2 }}
              />
            </Grid>
          </Grid>
        </Card>
      </Container>

      <Divider sx={{ mt: 8, mb: 8 }} />

      <Container component="footer">
        <Grid container justifyContent="space-between" rowSpacing={7}>
          <Grid item xs={12} md={6}>
            <Box component="img" src={logo} />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
              Unlock the world of decentralized finance effortlessly with
              CashFlowApp. Stake, borrow, and swap assets securely while
              enjoying a user-friendly experience. Your gateway to DeFi.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="subtitle1" gutterBottom>
              Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Be the first one to know about discounts, offers and events.
              Unsubscribe whenever you like.
            </Typography>

            <StyledTextField
              fullWidth
              placeholder="Enter your email"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon fontSize="small" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button padding="6px 22px" color="secondary">
                      Submit
                    </Button>
                  </InputAdornment>
                ),
              }}
              sx={{ mt: 3, mb: 3 }}
            />

            <AvatarWrapper>
              <AvatarIcon>
                <a
                  href="https://twitter.com/TheCashFlowApp"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  <TwitterIcon />
                </a>
              </AvatarIcon>

              <AvatarIcon>
                <a
                  href="https://www.linkedin.com/company/cashflowapp"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "inherit" }}
                >
                  <LinkedInIcon />
                </a>
              </AvatarIcon>
              {/* <AvatarIcon>
                <TelegramIcon />
              </AvatarIcon> */}
            </AvatarWrapper>
          </Grid>
        </Grid>
        {/*  */}

        <Box sx={{ display: { xs: "block", md: "none" } }}>
          <Gapper gap={28} />
        </Box>

        {/*  */}
        <Grid container>
          <Grid item xs={12} md={6}>
            <Grid container item rowSpacing={4}>
              {/* <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" spacing={1.4}>
                  <AvatarIcon>
                    <PhoneInTalkIcon color="secondary" />
                  </AvatarIcon>
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Have a question?
                    </Typography>
                    <Typography variant="subtitle2" color="secondary">
                      coming soon
                    </Typography>
                  </div>
                </Stack>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" spacing={1.4}>
                  <AvatarIcon>
                    <EmailOutlinedIcon color="secondary" />
                  </AvatarIcon>
                  <div>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Contact us at
                    </Typography>
                    <Typography variant="subtitle2" color="secondary">
                      support@cashflowapp.com
                    </Typography>
                  </div>
                </Stack>
              </Grid>
              {/* <Grid
                container
                item
                xs={12}
                justifyContent="center"
                rowSpacing={4}
                sx={{ textAlign: "center" }}
              >
                <Grid item xs={4} md={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    About us
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Contact
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Privacy policy
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Sitemap
                  </Typography>
                </Grid>
                <Grid item xs={4} md={2}>
                  <Typography variant="subtitle2" gutterBottom>
                    Terms of Use
                  </Typography>
                </Grid>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Divider sx={{ mt: 3 }} />

      <Container component="footer">
        <FooterText color="text.secondary">
          Copyright © {new Date().getFullYear()} TheCashflowApp | All Rights
          Reserved
        </FooterText>
      </Container>
    </Box>
  );
};

export default Footer;
