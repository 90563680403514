import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Slide,
  useScrollTrigger,
} from "@mui/material";
import { styled } from "@mui/system";

import SideDrawer from "./SideDrawer";

import Button from "../components/Button";

import Logo from "../assets/png/logo.png";

import Moon from "../assets/png/moon.png";
import Sun from "../assets/png/sun.png";

interface HeaderProps {
  onToggleTheme: () => void;
  isDarkMode: boolean;
}

const StyledToolbar = styled(Toolbar)`
  height: 6rem;
  padding: 0px 100px !important;
  @media (max-width: 1200px) {
    padding: 0px 24px !important;
  }
`;

const LogoWrapper = styled(Box)(({ theme }) => ({
  lineHeight: 0,
  [theme.breakpoints.down("md")]: {
    flex: 1,
  },
}));

const StyledLogo = styled(Box)<{ src?: string }>(({ theme }) => ({
  width: 180,
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    width: 120,
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  flex: 1,
  lineHeight: 0,
  display: "flex",
  alignItems: "center",
  gap: "0.8rem",
  marginLeft: theme.spacing(7.4),

  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const Items = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  cursor: "pointer",
  opacity: 0.8,
}));

const Switch = styled(Box)(({ theme }) => ({
  display: "flex",
  background: theme.palette.mode === "dark" ? "#FFFFFF14" : "#00000014",
  borderRadius: "24px",
  width: "104px",
  justifyContent: "space-evenly",
  padding: "4px 0px",
}));

const SwitchButton = styled(Box)<{ isActive?: boolean }>(
  ({ theme, isActive }) => ({
    padding: "8px",
    borderRadius: "50%",
    backgroundColor: isActive
      ? theme.palette.mode === "dark"
        ? "#FFFFFF1A"
        : "#0000001A"
      : "transparent",
    cursor: "pointer",
  })
);

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function HideOnScroll(props: Props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Header: React.FC<HeaderProps> = ({ onToggleTheme, isDarkMode }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isScrolledDown, setIsScrolledDown] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);
  const [navigateToSection, setNavigateToSection] = React.useState<
    string | null
  >(null);

  const StyledAppBar = styled(AppBar)(({ theme }) => ({
    background: isScrolledDown
      ? theme.palette.background.default
      : "transparent",
    boxShadow: "none",
  }));

  const handleNavigateToSection = (sectionId: string) => {
    if (location.pathname !== "/") {
      setNavigateToSection(sectionId);
      navigate("/");
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolledDown(true);
    } else {
      setIsScrolledDown(false);
    }
  };

  // Adding and removing the scroll listener
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  React.useEffect(() => {
    if (location.pathname === "/" && navigateToSection) {
      setTimeout(() => {
        const element = document.getElementById(navigateToSection);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
        setNavigateToSection(null);
      }, 100);
    }
  }, [location.pathname, navigateToSection]);

  return (
    <HideOnScroll>
      <StyledAppBar>
        <StyledToolbar>
          <LogoWrapper>
            <StyledLogo
              component="img"
              src={Logo}
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
            />
          </LogoWrapper>

          <StyledBox>
            <Items onClick={() => handleNavigateToSection("feature-section")}>
              Features
            </Items>
            <Items onClick={() => navigate("/about-us")}>About Us</Items>
            {/* <Items onClick={() => navigate("/faqs")}>FAQs</Items>
            <Items onClick={() => navigate("/contact-us")}>Contact us</Items> */}
          </StyledBox>

          <Box
            sx={{ display: { xs: "none", md: "block" }, mr: 3, lineHeight: 0 }}
          >
            <Switch>
              <SwitchButton
                isActive={isDarkMode}
                onClick={!isDarkMode ? onToggleTheme : () => console.log("")}
              >
                <Box component={"img"} src={Moon} height={20} />
              </SwitchButton>
              <SwitchButton
                isActive={!isDarkMode}
                onClick={isDarkMode ? onToggleTheme : () => console.log("")}
              >
                <Box component={"img"} src={Sun} height={20} />
              </SwitchButton>
            </Switch>
          </Box>

          <Button
            paddingMobile
            color="secondary"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {isHovered ? "Coming Soon" : "Launch App"}
          </Button>

          <Box sx={{ display: { xs: "block", md: "none" }, ml: 1 }}>
            <SideDrawer
              handleNavigateToSection={handleNavigateToSection}
              onToggleTheme={onToggleTheme}
              isDarkMode={isDarkMode}
            />
          </Box>
        </StyledToolbar>
      </StyledAppBar>
    </HideOnScroll>
  );
};

export default Header;
