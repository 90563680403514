import React from "react";

interface ThemeContextProps {
  toggleTheme: () => void;
  isDarkMode: boolean;
}

const ThemeContext = React.createContext<ThemeContextProps>({
  toggleTheme: () => {},
  isDarkMode: false,
});

export default ThemeContext;
